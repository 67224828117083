import * as React from "react";

import { PageLink } from "../components/InternalLink";
import NavBar from "../components/navBar";
import { PageProps } from "gatsby";

// markup
export default function NotFoundPage(props: PageProps) {
  return (
    <main className="bg-indigo-600 text-white h-screen">
      <NavBar {...props} />
      <div className="p-20 text-base">
        <title>Not found</title>
        <h1 className="text-3xl">Page not found</h1>
        <p className="mt-10">
          Sorry{" "}
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>{" "}
          we couldn’t find what you were looking for.
          <br />
          <br />
          <PageLink href="/" link="404_GO_HOME">
            Go home →
          </PageLink>
        </p>
      </div>
    </main>
  );
};
